body {
  margin:0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-smoothing :antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family :source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace
}
body {
  background-color: #282c34;
}
.App {
  flex-direction: column;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:root {
  /*--text-color: #edf5fc;*/
  --text-color: white;
}


.App, .App-header {
  display: flex;
  color: var(--text-color);
  align-items: center;
}
.App-header {
  flex-direction: row;
  justify-content: center;
  height: 50px;
}

.page, .App-header, .aboutMeScreen {
  padding-left: 1em;
  padding-right: 1em;
  background-color:rgba(40,44,52,.95);
}

#hexGrid {
  min-height: calc(100vh + 2 * max(5vh, 5vw));
  min-width: calc(100vw + 2 * max(5vh, 5vw));
  position: fixed;
  top: calc(min(-5vh, -5vw));
  left: calc(min(-5vh, -5vw));
  z-index: -3;
}

@media all and (orientation:landscape) {
  .page, .App-header, .aboutMeScreen {
    width: 70%;
  }

  .electionsLink {
    width: 15%;
  }
}

@media all and (orientation:portrait) {
  .page, .App-header, .aboutMeScreen {
    width: calc(100% - 2em);
  }

  .electionsLink {
    width: 30%;
  }
}

.App-header a {
  text-decoration: none
}

a {
  color: var(--text-color);
}

.headerLink {
  margin-left: 20px;
  margin-right: 20px
}

a:hover,.headerLink:hover {
  opacity: 0.9
}
a:active, .headerLink:active {
  opacity: 0.6
}

.App-header button, p {
  border: none;
  color: var(--text-color);
  font-size: 16px;
  padding: 0
}

button:hover{
  opacity: .7
}

button:active{
  opacity: .3
}

.aboutMeScreen, .page {
  height: calc((100 * var(--vh, 1vh)) - 50px);
  overflow-y: scroll;
}

.aboutMeScreen{
  justify-content: flex-start;
}

.aboutMeScreen, .aboutMeSection {
  display: flex;
  flex-direction: column
}

.aboutMeSection { 
  align-items: flex-start;
  margin-bottom: 1.5em;
}

.aboutMeScreen *, .noMarginBlock *, .page *, .App-header p {
  margin-block-start: 0;
  margin-block-end: 0
}

.aboutMeSection p, .homeScreen p {
  margin-bottom: 1.5em;
}

.homeScreen p {
  margin-top: 1em
}

.aboutMeScreen p,li,ul{
  text-align: left;
  line-height: 1.25em;
}

.schoolTitle {
  font-weight: 700;
  margin-block-end: .2em;
  font-size: 18px
}

.aboutMeScreen hr{
  width: 98%;
  margin-inline: 0;
  opacity: .3
}


.treemapButton {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  font-size: 24px;
  padding-bottom: 5px;
  box-shadow: none;
  border: 1px solid gray;
  background-color: rgba(200, 200, 200, 0.7);
}

.treemap {
  height: 500px;
  width: 500px;
}

.treemapKey {
  height: 160px;
  width: 500px;
}

.evKey {
  height: 100px;
  width: 700px;
}

.resizable {
  position: relative;
  resize: both;
  overflow: hidden;
  border: 10px solid white;
  background-color: white;
  max-height: fit-content;
  max-width: fit-content;
  margin-bottom: 10px;

}

.sectionHeader {
  width: 100%;
}

.openSH {
  margin-bottom: 0.5em;
}

.closedSH {
  margin-bottom: 1em;
}

.yearSlider {
  background-color: white;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px; 
  color: black;
  padding-bottom: 10px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.electionsLink {
  margin-bottom: 20px;
  text-decoration: none;
  padding: 40px;
  border-radius: 80px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center
}

.buttonSH {
  margin-left: auto;
  margin-right: 1em;
  border: none;
  background: none;
  color: white;
  font-size: 18px;
}