:root {
  --win-gif: url(https://i.pinimg.com/originals/e6/f8/0b/e6f80be8122d7019c37b39b02dd6ec2c.gif)
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.topBar {
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.topBar .flexCol {
  align-items: center;
}

.Picross h5, .Picross p {
  margin-block-end: 0;
  margin-block-start: 0;
}

#gameControls {
  display: grid;
}

#colNumbers {
  grid-column: 2;
  grid-row: 1;
}

@media all and (orientation:landscape) {
  #gameControls {
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
  }
  #mainBox {
    grid-column: 2;
    grid-row: 2;
    height: calc(min(var(--height) * var(--boxSize), var(--vh, 1vh) * 65));
  }
  .boxButtons.left {
    grid-column: 2;
    grid-row: 3;
    height: 50px;
  }
  .boxButtons.right {
    grid-column: 3;
    grid-row: 2;
    width: 50px;
  }
  #rowNumbers {
    grid-column: 1;
    grid-row: 2;
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(var(--height), var(--boxSize));
    overflow-y: scroll;
    height: calc(min(var(--height) * var(--boxSize), var(--vh, 1vh) * 65));
    margin-right: 5px;
  }
  .rowNumber {
    align-items: center;
    justify-content: flex-end;
  }
  .boxButtons.left button {
    height: 90%;
  }
  .boxButtons.right button {
    width: 90%;
  }

  .settings {
    padding: 0.5em 15vw;
  }

}

@media all and (orientation:portrait) {
  #gameControls {
    grid-template-columns: calc(10vw - 1px) calc(min(var(--width) * var(--boxSize), 80vw) + 2px) calc(10vw - 1px);
    grid-template-rows: auto auto calc(var(--vh, 1vh) * 15);
    width: calc(20vw + min(var(--width) * var(--boxSize), 80vw));;
  }
  #mainBox {
    grid-column: 2;
    grid-row: 2;
    height: calc(min(var(--height, 15) * var(--boxSize, 20px), var(--vh, 1vh) * 55));
  }
  .boxButtons.left {
    grid-column: 1;
    grid-row: 2;
  }
  .boxButtons.right {
    grid-column: 3;
    grid-row: 2;
  }
  #rowNumbers {
    grid-column: 2;
    grid-row: 3;
    height: calc(var(--vh, 1vh) * 15);
    margin-top: 5px;
    overflow: scroll;
    align-items: center;
    justify-content: flex-start;
  }
  .boxButtons {
    width: calc(10vw - 1px);
    max-height: calc(var(--vh, 1vh) * 55);
  }
  .boxButtons button {
    width: 90%;
  }
  .settings {
    padding: 0.5em 1em;
  }

}


.center {
  width: 100%;
  justify-content: center;
  max-height: calc(var(--vh, 1vh) * 55);
}



.centerCenter, .boxButtons {
  justify-content: center;
  align-items: center;
}


.boxButtons button {
  aspect-ratio: 1/1;
  margin: 1px 0;
  border: none;
  font-size: 12px;
  font-weight: bold;
  color: black;
  padding: 0;
}

#mainBox {
  display: grid;
  grid-template-columns: repeat(var(--width, 15), var(--boxSize, 20px));
  grid-template-rows: repeat(var(--height, 15), var(--boxSize, 20px));
  overflow: scroll;
  width: calc(min(var(--width, 15) * var(--boxSize, 20px), 80vw));
  border: 1px solid gray;
}

#colNumbers {
  display: grid;
  grid-template-columns: repeat(var(--width), var(--boxSize));
  grid-template-rows: repeat(1, auto);
  margin-bottom: 5px;
  width: calc(min(var(--width) * var(--boxSize), 80vw));
  overflow-x: scroll;
}

.item {
  background-color: white;
  display: flex;
  font-size: calc(var(--boxSize) / 2);
  color: #900
}

.notBottomEdge {
  border-bottom: 1px solid #ddd;
}

.cellBottomEdge {
  border-bottom: 1px solid black;
}


.cellRightEdge {
  border-right: 1px solid black;
}

.notRightEdge {
  border-right: 1px solid #ddd;
}

.blue {
  background-color: cyan;
}

.gray {
  background-color: #ccc;
}

.winGif {
  background-image: var(--win-gif);
  background-size: contain;
}

#boardSeed {
  max-width: 80vw;
  word-wrap: break-word;
}

.highlighted {
  background-color:  rgba(255, 200, 0, 0.1)
}

.focused {
  box-shadow: 0 0 calc(var(--boxSize) / 4) calc(var(--boxSize) / 15) orange inset;
}




.numberCol {
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-start;
  align-items: center;

}

.Picross {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-color: white;
}

#boxCols {
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 80);
  overflow: hidden;
  align-items: center;
}

.modeHighlighted {
  box-shadow: 0 0 15px 3px orange inset;
}

.settings {
  align-items: center;
  justify-content: flex-start;
}

.settings p {
  margin-bottom: 1em;
}

.Picross .topBar *, .groupNum, .rowNumbers, .settings *, .calcPopup *, .acknowledgement * {
  color: black;
}

.crossedOut {
  color: #aaa;
  text-decoration: line-through;
}

.calcPopup {
  position: fixed;
  top: calc(50% - (var(--vh, 1vh) * 15));
  left: calc(50% - 40vw);
  background-color: rgba(255, 255, 255, 0.9);
  height: calc(var(--vh, 1vh) * 30);
  width: 80vw;
}

.acknowledgement {
  text-align: center;
  margin-top: 1em;
}

.newGameButton {
  border: none;
  border-radius: 20px;
  padding: 3px 6px;
}

.settings .flexRow label {
  margin-right: 10px;
}
